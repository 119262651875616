import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  ButtonGroup,
  TextField,
  Grid,
  Button,
  Typography,
  Paper,
  IconButton,
  InputAdornment
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { useApi } from "../API";

const AUTH_TYPE = window._env_.REACT_APP_AUTH_TYPE;

const ResetPasswordForm = () => {
  const { logout, user } = useAuth0();
  const auth0_connection = user?.sub.startsWith("auth0") && AUTH_TYPE === "auth0";
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordError, setNewPasswordError] = useState();
  const [confirmedPassword, setConfirmedPassword] = useState("");
  const [confirmedError, setConfirmedError] = useState();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const handleClickShowCurrentPassword = () => setShowCurrentPassword(!showCurrentPassword);
  const handleMouseDownCurrentPassword = () => setShowCurrentPassword(!showCurrentPassword);

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const [showConfirmedPassword, setConfirmedShowPassword] = useState(false);
  const handleClickShowConfirmedPassword = () => setConfirmedShowPassword(!showConfirmedPassword);
  const handleMouseDownConfirmedPassword = () => setConfirmedShowPassword(!showConfirmedPassword);

  const api = useApi();

  const isValid = (string) => {
    return (string.length >= 8 && string.length <= 128 && /[A-Z]/.test(string) && /\d/.test(string) && /[!@#$%^&*]/.test(string));
  };

  const resetPassword = async () => {
    setError(false);
    setLoading(true);

    try {
      if (AUTH_TYPE === "auth0") {
        await api.resetAuth0Password(newPassword);
        logout();
      } else {
        await api.resetPassword(currentPassword, newPassword);
        await api.logout();
        window.location.href = "/login";
      }
    } catch (e) {
      setError(true);
    } finally {
      setLoading(false);
      setConfirmedError();
      setNewPasswordError();
      setConfirmedPassword("");
      setNewPassword("");
      setCurrentPassword("");
    }
  };
  if (error) {
    return <div>Error resetting password</div>;
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {((AUTH_TYPE !== "okta" && AUTH_TYPE !== "auth0") || auth0_connection) && (
        <Paper variant="outlined" style={{ padding: "3em" }}>
          <Grid container direction="column" justifyContent="center" spacing={3}>
            <Grid item xs={3}>
              <Typography variant="subtitle1">Reset password</Typography>
              <Typography variant="body2">
                You will redirected to the login page.
              </Typography>
            </Grid>
            {(!auth0_connection && AUTH_TYPE !== "auth0" && AUTH_TYPE !== "auth0") && (
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  type={showCurrentPassword ? "text" : "password"}
                  value={currentPassword}
                  onInput={(e) => {
                    setCurrentPassword(e.target.value);
                  }}
                  variant="outlined"
                  label="Current Password"
                  InputProps={{ // <-- This is where the toggle button is added.
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowCurrentPassword}
                          onMouseDown={handleMouseDownCurrentPassword}
                        >
                          {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
            )}
            <Grid item xs={3}>
              <TextField
                fullWidth
                value={newPassword}
                type={showPassword ? "text" : "password"}
                onInput={(e) => {
                  setNewPassword(e.target.value);
                  setNewPasswordError(!isValid(e.target.value));
                }}
                variant="outlined"
                label="New Password"
                error={newPasswordError}
                helperText="Must be at least 8 characters long and have at least 1 uppercase character, number, and special character"
                InputProps={{ // <-- This is where the toggle button is added.
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                value={confirmedPassword}
                onInput={(e) => {
                  setConfirmedPassword(e.target.value);
                  setConfirmedError(e.target.value !== newPassword);
                }}
                variant="outlined"
                label="Confirm New Password"
                type={showConfirmedPassword ? "text" : "password"}
                error={confirmedError}
                InputProps={{ // <-- This is where the toggle button is added.
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfirmedPassword}
                        onMouseDown={handleMouseDownConfirmedPassword}
                      >
                        {showConfirmedPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item>
              <ButtonGroup>
                <Button
                  disableElevation
                  disabled={
                    confirmedError ||
                    newPasswordError ||
                    !newPassword.length ||
                    !confirmedPassword.length
                  }
                  color="secondary"
                  onClick={resetPassword}
                  variant="contained"
                >
                  Reset Password
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        </Paper>
      )}
      {(!auth0_connection && AUTH_TYPE === "auth0") && (
        <Paper variant="outlined">
          <Typography> You do not have permission to view this page, your account is not an Auth0 provided account. </Typography>
        </Paper>
      )}
    </>
  );
};

export default ResetPasswordForm;
