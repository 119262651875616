import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import queryString from 'query-string';
import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import Toolbar from "@material-ui/core/Toolbar";
import { Button } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
//import { useHistory } from "react-router-dom";
import logo from "../assets/logo.svg";
import { palette } from "../utils/theme";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

const Auth0Auth = () => {
  const { loginWithRedirect } = useAuth0();
  const location = useLocation();
  const query = queryString.parse(location.search);
  const { invitation, organization } = query;

  const handleLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/app",
      },
    });
  };

  useEffect(() => {
    if (!!invitation && !!organization) {
      loginWithRedirect({
        authorizationParams: {
          organization,
          invitation
        },
        appState: {
          returnTo: '/app'
        }
      });
    }
  });


  return (
    <div>
      <AppBar>
        <Toolbar>
          <IconButton color="inherit" aria-label="open drawer" edge="start" />
          <a href="/login" style={{ height: "23px", marginLeft: ".3em" }}>
            <img height="23px" src={logo} alt="Logo" />
          </a>
        </Toolbar>
      </AppBar>
      <Grid
        container
        style={{ paddingTop: "120px" }}
        justifyContent="center"
        alignItems="center"
        direction="column"
        spacing={0}
      >
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="column"
          spacing={2}
        >
          <Grid item xs={12}>
            <Button
              style={{
                top: '30vh',
                bottom: '30vh',
                maxWidth: '100px', 
                maxHeight: '50px', 
                minWidth: '100px', 
                minHeight: '50px',
              }}
              color="primary"
              data-test="login-submit-btn"
              type="submit"
              variant="contained"
              onClick={handleLogin}
            >
              Login
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Auth0Auth;
