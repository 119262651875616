import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
  ButtonGroup,
  TextField,
  Grid,
  Button,
  Typography,
  IconButton,
  Checkbox,
  FormGroup,
  FormControlLabel
} from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import Settings from "@material-ui/icons/Settings";
import { useApi } from "../API";
import { palette } from "../utils/theme";
import { emailIsValid } from "../utils";
import StyledModal from "../components/StyledModal";

const AUTH_TYPE = window._env_.REACT_APP_AUTH_TYPE;

const Users = () => {
  const api = useApi();
  const { user } = useAuth0();
  const [permissions, setPermissions] = useState([]);
  const privileges = permissions?.includes("change:users") && AUTH_TYPE === "auth0";
  const [newUserEmailError, setNewUserEmailError] = useState(false);
  const [users, setUsers] = useState({ loading: true, error: false, data: [] });
  const [auth0Users, setAuth0Users] = useState({ loading: true, error: false, data: [] });
  const [auth0OrgUsers, setAuth0OrgUsers] = useState({ loading: true, error: false, data: [] });
  const [newUser, setNewUser] = useState({
    loading: false,
    error: false,
    data: "",
  });
  const [deleteUser, setDeleteUser] = useState({
    loading: false,
    error: false,
    data: "",
  });
  const [roles, setRoles] = useState({
    loading: false,
    error: false,
    data: []
  });
  const [selectedRoles, setSelectedRoles] = useState({
    loading: true,
    data: {}
  });
  const [userRoles, setUserRoles] = useState({
    loading: false,
    error: false,
    data: [],
  })
  const [changeUserRoles, setChangeUserRoles] = useState({
    loading: false,
    error: false,
    data: "",
  });
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [addUserModalOpen, setAddUserModalOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState({});
  const [rolesModalOpen, setRolesModalOpen] = useState(false);
  const [userToChangeRoles, setUserToChangeRoles] = useState({});
  const [reloadUsers, setReloadUsers] = useState(false);
  const [newUserEmail, setNewUserEmail] = useState("");
  const [confiredEmail, setConfirmedEmail] = useState("");

  const addUser = async () => {
    setNewUser({ ...newUser, loading: true, error: false });
    if (AUTH_TYPE === "auth0") {
      try {
        await api.inviteAuth0User(user.name, newUserEmail);
        setNewUser({
          loading: false,
          error: false,
          data: "Successfully added user.",
        });
        setAddUserModalOpen(false);
        setReloadUsers(!reloadUsers);
      } catch (e) {
        setNewUser({ loading: false, error: true, data: "Error adding user" });
      } finally {
        setNewUserEmail("");
        setConfirmedEmail("");
        setNewUserEmailError(false);
      }
    } else if (AUTH_TYPE !== "okta" && AUTH_TYPE !== "auth0") {
      try {
        await api.inviteUser(newUserEmail);
        setNewUser({
          loading: false,
          error: false,
          data: "Successfully added user.",
        });
        setAddUserModalOpen(false);
        setReloadUsers(!reloadUsers);
      } catch (e) {
        setNewUser({ loading: false, error: true, data: "Error adding user" });
      } finally {
        setNewUserEmail("");
        setConfirmedEmail("");
        setNewUserEmailError(false);
      }
    }
  };

  const handleDeleteUser = async (userId) => {
    setDeleteUser({ ...deleteUser, loading: true, error: false });
    if (AUTH_TYPE === "auth0") {
      try {
        await api.deleteAuth0User(userId);
        setDeleteUser({
          loading: false,
          error: false,
          data: "Successfully deleted user.",
        });
        setUserToDelete({});
        setDeleteModalOpen(false);
        setReloadUsers(!reloadUsers);
      } catch (e) {
        setDeleteUser({
          loading: false,
          error: true,
          data: "Error deleting user",
        });
      }
    } else if (AUTH_TYPE !== "okta" && AUTH_TYPE !== "auth0") {
      try {
        await api.deleteUser(userId);
        setDeleteUser({
          loading: false,
          error: false,
          data: "Successfully deleted user.",
        });
        setUserToDelete({});
        setDeleteModalOpen(false);
        setReloadUsers(!reloadUsers);
      } catch (e) {
        console.log("delete error is", e);
        setDeleteUser({
          loading: false,
          error: true,
          data: "Error deleting user",
        });
      }
    }
  };

  const handleUserRoles = async (userId) => {
    setSelectedRoles({ ...selectedRoles, loading: true })
    setUserRoles({ ...userRoles, loading: true, error: false });
    try {
      const data = await api.getAuth0OrgUserRoles(userId);
      setUserRoles({
        loading: false,
        error: false,
        data
      })
      let rolesToSelect = {};
      for (let i = 0; i < roles.data.length; i++) {
        for (let j = 0; j < data.length; j++) {
          if (roles.data[i].id === data[j].id) {
            rolesToSelect[roles.data[i].id] = true;
            break;
          }
          else {
            rolesToSelect[roles.data[i].id] = false;
          }
        }
      }
      setSelectedRoles({ data: rolesToSelect, loading: false });
    } catch (e) {
      setUserRoles({
        loading: false,
        error: true,
        data: "error getting user roles"
      })
      setSelectedRoles({
        loading: false,
        data: "error setting selected roles"
      })
    }
  }

  const handleChangeUserRoles = async (userId, selectedRoles, previousSelectedRoles) => {
    setChangeUserRoles({ ...changeUserRoles, loading: true, error: false });
    try {
      await api.changeAuth0OrgUserRoles(userId, selectedRoles, previousSelectedRoles);
      setChangeUserRoles({
        loading: false,
        error: false,
        data: "Successfully updated user's roles.",
      });
      setUserToChangeRoles({});
      setRolesModalOpen(false);
    } catch (e) {
      setChangeUserRoles({
        loading: false,
        error: true,
        data: "Error changing user's roles.",
      });
    }
  };

  const createUserList = (auth0Users, auth0OrgUsers) => {
    setUsers({ ...users, loading: true, error: false})
    let usersList = []
    for (let i = 0; i < auth0OrgUsers.length; i++) {
      for (let j = 0; j < auth0Users.length; j++) {
        if (auth0OrgUsers[i].user_id == auth0Users[j].user_id) {
          usersList.push(auth0Users[j]);
          break;
        }
      }
    }
    if (usersList.length < 1) {
        setUsers({ loading: true, error: false, data: usersList })
    } else {
        setUsers({ loading: false, error: false, data: usersList })
    }
  }

  useEffect(async () => {
    if (AUTH_TYPE === "auth0") {
      try {
        const perms = await api.getAuth0TokenPermissions();
        setPermissions(perms.permissions);
      } catch (e) {
        setPermissions([])
      }
      setAuth0Users({ ...auth0Users, loading: true, error: false });
      try {
        const data = await api.getAuth0Users();
        setAuth0Users({ loading: false, error: false, data });
      } catch (e) {
        setAuth0Users({ loading: false, error: true, data: [] });
      }
      setAuth0OrgUsers({ ...auth0OrgUsers, loading: true, error: false });
      try {
        const data = await api.getAuth0OrgUsers();
        setAuth0OrgUsers({ loading: false, error: false, data });
      } catch (e) {
        setAuth0OrgUsers({ loading: false, error: true, data: [] });
      }
      if (auth0Users.data.length < 1 || auth0OrgUsers.data.length < 1) {
        setReloadUsers(!reloadUsers)
      }
      createUserList(auth0Users.data, auth0OrgUsers.data);
      setRoles({ ...roles, loading: true, error: false });
      try {
        const data = await api.getAuth0Roles();
        setRoles({ loading: false, error: false, data });
      } catch (e) {
        setRoles({ loading: false, error: true, data: [] });
      }

    } else if (AUTH_TYPE !== "okta" && AUTH_TYPE !== "auth0") {
      setUsers({ ...users, loading: true, error: false });
      try {
        const data = await api.getUsers();
        setUsers({ loading: false, error: false, data });
      } catch (e) {
        setUsers({ loading: false, error: true, data: [] });
      }
    }
  }, [reloadUsers]);

  const handleOpenDeleteModal = (user) => {
    setUserToDelete(user);
    setDeleteModalOpen(true);
  };

  const handleOpenRolesModal = (user) => {
    setUserToChangeRoles(user);
    setRolesModalOpen(true);
  }

  const handleCheckBoxChange = (event) => {
    let rolesToSelect = { ...selectedRoles.data };
    rolesToSelect[event.target.name] = event.target.checked;
    setSelectedRoles({ data: rolesToSelect, loading: false });
  }

  if (users.loading) {
    return <div>Loading...</div>;
  }

  if (users.error) {
    return <div>Error</div>;
  }

  return (
    <>
      {AUTH_TYPE === "auth0" && (
        <div>
          {privileges && (
            <Paper variant="outlined">
              <TableContainer>
                <Table size="small" sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell width="5%" />
                      <TableCell>Name</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Provider</TableCell>
                      <TableCell>Account Created</TableCell>
                      <TableCell> </TableCell>
                      <TableCell style={{ textAlign: "right" }}>
                        <Button
                          startIcon={<AddIcon />}
                          variant="contained"
                          color="secondary"
                          size="small"
                          disableElevation
                          onClick={() => {
                            setAddUserModalOpen(true);
                          }}
                        >
                          Invite User
                        </Button>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {users.data.map((list_user, idx) => (
                      <TableRow key={idx}>
                        <TableCell width="5%">
                          <Avatar>
                            <PersonIcon style={{ color: "white" }} />
                          </Avatar>
                        </TableCell>
                        <TableCell>
                          <Typography variant="subtitle2">
                            {" "}
                            {list_user.name}
                          </Typography>
                        </TableCell>
                        {list_user.email_verified && (
                          <TableCell>{user.email} (Verified)</TableCell>
                        )}
                        {!list_user.email_verified && (
                          <TableCell>{list_user.email} (Unverified)</TableCell>
                        )}
                        <TableCell>{list_user.identities[0].provider}</TableCell>
                        <TableCell>{list_user.created_at}</TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          {list_user.user_id !== user.sub && (
                            <Button
                              startIcon={<Settings />}
                              variant="contained"
                              color="secondary"
                              size="small"
                              disableElevation
                              onClick={() => {
                                handleUserRoles(list_user.user_id);
                                handleOpenRolesModal(list_user);
                              }}
                            >
                              Roles
                            </Button>
                          )}
                        </TableCell>
                        <TableCell style={{ textAlign: "right" }}>
                          <ButtonGroup>
                            <IconButton
                              onClick={() => {
                                handleOpenDeleteModal(list_user);
                              }}
                            >
                              <CloseIcon style={{ color: palette.red }} />
                            </IconButton>
                          </ButtonGroup>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <StyledModal
                open={rolesModalOpen}
                title="Change User's Roles"
                onClose={() => {
                  setChangeUserRoles({
                    loading: false,
                    error: false,
                    data: "",
                  });
                  setUserToChangeRoles({});
                  setRolesModalOpen(false);
                }}
                onClick={(e) => {
                  e.preventDefault();
                  handleUserRoles(userToChangeRoles.user_id);
                }}
                onSubmit={(e) => {
                  e.preventDefault();
                  handleChangeUserRoles(userToChangeRoles.user_id, selectedRoles.data, userRoles.data); //TODO
                }}
              >
                {userRoles.loading && selectedRoles.loading && (
                  <Typography>Loading...</Typography>
                )}
                {!userRoles.loading && !selectedRoles.loading && (
                  <Grid container spacing={2} direction="column">
                    {changeUserRoles.error && (
                      <Typography style={{ color: "red" }}>
                        There was an error changing the user's roles.
                      </Typography>
                    )}
                    {changeUserRoles.loading && <Typography>Loading...</Typography>}
                    {roles.data.map((role, idx) => (
                      <Grid key={idx} item>
                        <FormGroup>
                          <FormControlLabel
                            name={role.id}
                            checked={selectedRoles["data"][role.id]}
                            control={<Checkbox />}
                            onChange={handleCheckBoxChange}
                            label={role.name + " - " + role.description} />
                        </FormGroup>
                      </Grid>
                    ))}
                    <Grid item>
                      <Typography variant="subtitle1">
                        Are you sure you want to change{" "}
                        <strong>{userToChangeRoles.name}</strong>'s roles?
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </StyledModal>

              <StyledModal
                open={deleteModalOpen}
                title="Delete User"
                onClose={() => {
                  setDeleteUser({
                    loading: false,
                    error: false,
                    data: "",
                  });
                  setUserToDelete({});
                  setDeleteModalOpen(false);
                }}
                onClick={(e) => {
                  e.preventDefault();
                  handleDeleteUser(userToDelete.user_id);
                }}
                destructive
                onSubmit={(e) => {
                  e.preventDefault();
                  handleDeleteUser(userToDelete.user_id);
                }}
                submitDisabled={confiredEmail !== newUserEmail || newUserEmailError}
              >
                <Grid container spacing={2} direction="column">
                  {deleteUser.error && (
                    <Typography style={{ color: "red" }}>
                      There was an error deleting the user.
                    </Typography>
                  )}
                  {deleteUser.loading && <Typography>Loading...</Typography>}
                  <Grid item>
                    <Typography variant="subtitle1">
                      Are you sure you want to delete{" "}
                      <strong>{userToDelete.name}</strong>?
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle1">
                      This user will lose all access to KeyCaliber and must be
                      re-invited to join again.
                    </Typography>
                  </Grid>
                </Grid>
              </StyledModal>

              <StyledModal
                open={addUserModalOpen}
                title="Invite a New User to KeyCaliber"
                onClose={() => {
                  setNewUser({
                    loading: false,
                    error: false,
                    data: "",
                  });
                  setNewUserEmail("");
                  setConfirmedEmail("");
                  setNewUserEmailError(false);
                  setAddUserModalOpen(false);
                }}
                onSubmit={addUser}
                submitDisabled={confiredEmail !== newUserEmail
                  || newUserEmailError}
              >
                <Grid container spacing={2} direction="column">
                  {newUser.error && (
                    <Typography style={{ color: "red" }}>
                      There was an error adding the user.
                    </Typography>
                  )}
                  {newUser.loading && <Typography>Loading...</Typography>}
                  <Grid item>
                    <TextField
                      fullWidth
                      value={newUserEmail}
                      onInput={(e) => {
                        setNewUserEmailError(!emailIsValid(e.target.value));
                        setNewUserEmail(e.target.value);
                      }}
                      variant="outlined"
                      label="New User Email"
                      error={newUserEmailError}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      fullWidth
                      value={confiredEmail}
                      onInput={(e) => {
                        setConfirmedEmail(e.target.value);
                      }}
                      variant="outlined"
                      error={confiredEmail !== newUserEmail}
                      label="Re-type New User's Email"
                    />
                  </Grid>
                </Grid>
              </StyledModal>
            </Paper>
          )}
          {!privileges && (
            <Paper variant="outlined">
              <Typography> You do not have permission to view this page. </Typography>
            </Paper>
          )}
        </div>
      )}


      {(AUTH_TYPE !== "okta" && AUTH_TYPE !== "auth0") && (
        <div>
          <Paper variant="outlined">
            <TableContainer>
              <Table size="small" sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell width="5%" />
                    <TableCell>Username</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Account Created</TableCell>
                    <TableCell style={{ textAlign: "right" }}>
                      <Button
                        startIcon={<AddIcon />}
                        variant="contained"
                        color="secondary"
                        size="small"
                        disableElevation
                        onClick={() => {
                          setAddUserModalOpen(true);
                        }}
                      >
                        New User
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users.data.map((user, idx) => (
                    <TableRow key={idx}>
                      <TableCell width="5%">
                        <Avatar>
                          <PersonIcon style={{ color: "white" }} />
                        </Avatar>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle2">
                          {" "}
                          {user.username}
                        </Typography>
                      </TableCell>
                      <TableCell>{user.email}</TableCell>
                      <TableCell>{user.reg_date}</TableCell>
                      <TableCell style={{ textAlign: "right" }}>
                        <ButtonGroup>
                          <IconButton
                            onClick={() => {
                              handleOpenDeleteModal(user);
                            }}
                          >
                            <CloseIcon style={{ color: palette.red }} />
                          </IconButton>
                        </ButtonGroup>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <StyledModal
              open={deleteModalOpen}
              title="Delete User"
              onClose={() => {
                setDeleteUser({
                  loading: false,
                  error: false,
                  data: "",
                });
                setUserToDelete({});
                setDeleteModalOpen(false);
              }}
              onClick={(e) => {
                e.preventDefault();
                handleDeleteUser(userToDelete.id);
              }}
              destructive
              onSubmit={(e) => {
                e.preventDefault();
                handleDeleteUser(userToDelete.id);
              }}
              submitDisabled={confiredEmail !== newUserEmail || newUserEmailError}
            >
              <Grid container spacing={2} direction="column">
                {deleteUser.error && (
                  <Typography style={{ color: "red" }}>
                    There was an error deleting the user.
                  </Typography>
                )}
                {deleteUser.loading && <Typography>Loading...</Typography>}
                <Grid item>
                  <Typography variant="subtitle1">
                    Are you sure you want to delete{" "}
                    <strong>{userToDelete.username}</strong>?
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1">
                    This user will lose all access to KeyCaliber and must be
                    re-invted to join again.
                  </Typography>
                </Grid>
              </Grid>
            </StyledModal>
            <StyledModal
              open={addUserModalOpen}
              title="Invite a New User to KeyCaliber"
              onClose={() => {
                setNewUser({
                  loading: false,
                  error: false,
                  data: "",
                });
                setNewUserEmail("");
                setConfirmedEmail("");
                setNewUserEmailError(false);
                setAddUserModalOpen(false);
              }}
              onSubmit={addUser}
              submitDisabled={confiredEmail !== newUserEmail || newUserEmailError}
            >
              <Grid container spacing={2} direction="column">
                {newUser.error && (
                  <Typography style={{ color: "red" }}>
                    There was an error adding the user.
                  </Typography>
                )}
                {newUser.loading && <Typography>Loading...</Typography>}
                <Grid item>
                  <TextField
                    fullWidth
                    value={newUserEmail}
                    onInput={(e) => {
                      setNewUserEmailError(!emailIsValid(e.target.value));
                      setNewUserEmail(e.target.value);
                    }}
                    variant="outlined"
                    label="New User Email"
                    error={newUserEmailError}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    fullWidth
                    value={confiredEmail}
                    onInput={(e) => {
                      setConfirmedEmail(e.target.value);
                    }}
                    variant="outlined"
                    error={confiredEmail !== newUserEmail}
                    label="Re-type New User Email"
                  />
                </Grid>
              </Grid>
            </StyledModal>
          </Paper>
        </div>
      )}
    </>
  );
};

export default Users;
