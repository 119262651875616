import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  Typography,
  IconButton,
  TextField,
  MenuItem,
  Tooltip,
  Paper,
  CircularProgress,
  Divider,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import RefreshIcon from "@material-ui/icons/Refresh";
import { useApi } from "../API";
import { palette } from "../utils/theme";
import AccordionSection from "../components/AccordionSection";

export default function Upload({
  solutionName = "",
  connectorId = null,
  type = "default",
}) {
  const api = useApi();
  const [solution, setSolution] = useState(solutionName);
  const [pipelineStatus, setPipelineStatus] = useState({
    connectors: [],
    status: null,
  });
  const [selectedFile, setSelectedFile] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [uploadResponse, setUploadResponse] = useState();
  // eslint-disable-next-line no-unused-vars
  const [uploadError, setUploadError] = useState(false);
  const [uploadErrorMessage, setUploadErrorMessage] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [runPipelineLoading, setRunPipelineLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [runPipelineError, setRunPipelineError] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [uploadLoading, setUploadLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [validSolutions, setValidSolutions] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [selectFileLoading, setSelectFileLoading] = useState(false);
  // const findSolutionData = (solution) => {
  //   return validSolutions?.find((el) => el.name === solution);
  // };
  const [deleteStatus, setDeleteStatus] = useState({
    loading: false,
    error: false,
    success: false,
  });
  const purgeData = async () => {
    setDeleteStatus({ loading: true, error: false, success: false });
    try {
      await api.purgeData();
      setDeleteStatus({ loading: false, error: false, success: true });
    } catch (e) {
      console.log(e);
      setDeleteStatus({ loading: false, error: true, success: false });
    }
  };

  useEffect(() => {
    api
      .getConnectorOptions()
      .then((resp) => {
        setValidSolutions(resp?.solutions);
      })
      .catch((error) => console.log(error));
  }, []);

  const selectFile = ({ target: { files } }) => {
    setSelectFileLoading(true);
    if (files && files?.length) {
      setSelectedFile(files[0]);
    } else {
      setSelectedFile(null);
    }

    setSelectFileLoading(false);
  };

  const uploadFile = async () => {
    setUploadLoading(true);
    setUploadError(false);
    setUploadResponse(null);
    try {
      // Create the solution call
      // findSolutionData(solution)
      // get connector id from response
      // const response = { connector_id: 1 };
      // const response = await api.upsertConnectorForCSVUpload(solution);
      // console.log(response);

      // Upload to s3
      // use connector id
      if (selectedFile) {
        const response = await api.upload(
          selectedFile,
          solutionName || solution,
          connectorId
        );
        console.log("response", response);
        if (response.success === true) {
          setUploadResponse(true);
        } else {
          setUploadResponse(null);
          setUploadError(true);
          setUploadErrorMessage(response.message);
        }
      }
    } catch (e) {
      console.log("Upload error:", e);
      setUploadError(true);
    } finally {
      setUploadLoading(false);
    }
  };

  const runPipeline = async () => {
    setRunPipelineLoading(true);
    setRunPipelineError(false);
    try {
      await api.runPipeline(connectorId);
    } catch (e) {
      console.log(e);
      setRunPipelineError(true);
    } finally {
      setRunPipelineLoading(false);
    }
  };

  const checkPipelineStatus = async () => {
    let resp = {};
    setRunPipelineError(false);
    try {
      resp = await api.pipelineStatus(connectorId);
      resp.running = resp?.status === "Running";
      setPipelineStatus(resp);
    } catch (e) {
      console.log(e);
      setRunPipelineError(true);
    }
  };

  useEffect(async () => {
    const interval = setInterval(() => {
      checkPipelineStatus()
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Grid direction="row">
      <Paper style={{ padding: "1em", marginTop: "2em" }} variant="outlined">
        <Grid container>
          <Grid container item xs={6} spacing={2} fullWidth>
            <Grid item xs={12}>
              <Typography variant="h6">Upload Files</Typography>
            </Grid>
            {type === "default" && (
              <Grid item xs={6}>
                <TextField
                  select
                  fullWidth
                  variant="outlined"
                  name="solution"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Security Solution or Vendor"
                  placeholder="Data Category or Vendor"
                  value={solution}
                  error={!solution}
                  onChange={({ target: { value } }) => {
                    setSolution(value);
                  }}
                >
                  {validSolutions?.map((el, idx) => {
                    return (
                      <MenuItem key={idx} value={el.name}>
                        {el.display_name}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
            )}
            <Grid item xs={12}>
              {selectFileLoading && (
                <Typography variant="subtitle1">File loading....</Typography>
              )}
              {selectedFile && (
                <Typography variant="caption">
                  {" "}
                  {`File Selected: ${selectedFile.name}; Size: ${selectedFile.size} bytes`}{" "}
                </Typography>
              )}
            </Grid>
            <Grid item justifyContent="space-between" xs={12} width="100%">
              <Grid item xs={12} width="100%">
                <Grid direction="row" width="100%">
                  <Button
                    variant="outlined"
                    component="label"
                    disableElevation
                    color="secondary"
                    style={{ marginRight: 8 }}
                  >
                    Choose File
                    <input
                      multiple
                      accept=".csv"
                      type="file"
                      hidden
                      onChange={selectFile}
                    />
                  </Button>

                  <Button
                    variant="contained"
                    disableElevation
                    color="secondary"
                    disabled={
                      (!solution && type === "default") ||
                      !selectedFile ||
                      uploadLoading
                    }
                    onClick={uploadFile}
                  >
                    {uploadLoading && <CircularProgress />}
                    Upload File
                  </Button>
                </Grid>
                <Grid item>
                  {/* {!selectedFile && (
                    <Typography
                      variant="caption"
                      style={{ color: palette.red }}
                    >
                      Select a file to upload
                    </Typography>
                  )} */}
                  {uploadError && (
                    <Typography
                      variant="caption"
                      style={{ color: palette.red }}
                    >
                      {uploadErrorMessage || "Error Uploading File."}
                    </Typography>
                  )}
                  {uploadResponse && (
                    <Typography
                      variant="caption"
                      style={{ color: palette.green }}
                    >
                      Successfully Uploaded File
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={6} spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">Process Data</Typography>
            </Grid>
            <Grid item xs={12}>
              {!runPipelineLoading && (
                <Alert
                  severity={
                    pipelineStatus.running
                      ? "info"
                      : pipelineStatus.status === "Failed"
                        ? "error"
                        : "success"
                  }
                >
                  {pipelineStatus.status === "Failed"
                    ? "Error processing. Please run again. " + (pipelineStatus.error ? pipelineStatus.error : "")
                    : pipelineStatus.running
                      ? "Running Now! Check back later or hit refresh. Data Processing can take up to a few hours depending on data volume."
                      : "Last run completed successfully."}
                </Alert>
              )}
            </Grid>
            <Grid item xs={12}>
              {pipelineStatus.running && (
                <Tooltip title="Refresh Status">
                  <IconButton onClick={checkPipelineStatus} color="secondary">
                    <RefreshIcon />
                  </IconButton>
                </Tooltip>
              )}
              <Button
                variant="contained"
                disabled={pipelineStatus.running || runPipelineLoading}
                disableElevation
                color="secondary"
                onClick={runPipeline}
              >
                {runPipelineLoading && <CircularProgress />}

                {runPipelineLoading
                  ? "Submitting"
                  : pipelineStatus.running
                    ? "Currently Running"
                    : "Process Now"}
              </Button>
            </Grid>
            <Grid item xs={12}>
              {runPipelineError && (
                <Typography variant="caption" style={{ color: palette.red }} />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      {type === "default" && (
        <Paper style={{ padding: "1em", marginTop: "2em" }} variant="outlined">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h6">Past Runs</Typography>
              {pipelineStatus?.connectors?.map((el, idx) => {
                return (
                  <>
                    <Typography variant="subtitle2" display="block">
                      {el.name}
                    </Typography>
                    <Typography display="block" variant="body2" key={idx}>
                      {el.last_run} | {el.status}
                    </Typography>
                    <Typography display="block" variant="body2" key={idx}>
                      Rows Processed: {el.n_records}
                    </Typography>
                    <Divider style={{ width: "50%" }} />
                    <br />
                  </>
                );
              })}
            </Grid>
          </Grid>
        </Paper>
      )}
      {type === "default" && (
        <div style={{ marginTop: "6em" }}>
          <AccordionSection title="Delete All Data" flat>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Alert severity="warning">
                  Warning: This is will delete all asset data from KeyCaliber!
                </Alert>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  disableElevation
                  disabled={deleteStatus.loading}
                  style={{ backgroundColor: palette.darkRed, color: "white" }}
                  onClick={purgeData}
                >
                  {deleteStatus.loading && <CircularProgress />}
                  Delete Permanently
                </Button>
              </Grid>
              <Grid item>
                {deleteStatus.error && (
                  <Typography variant="caption" style={{ color: palette.red }}>
                    Error Deleting Data
                  </Typography>
                )}
              </Grid>
            </Grid>
          </AccordionSection>
        </div>
      )}
    </Grid>
  );
}
