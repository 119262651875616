import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Box,
  FormGroup,
  FormControlLabel,
  Grid,
  IconButton,
  Icon,
  MenuItem,
  Paper,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { ArrowForward } from "@material-ui/icons";
import moment from "moment";
import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { useApi } from "../../API";
import { palette } from "../../utils/theme";
import ToastContext from "../../context/ToastContext";
import Upload from "../../upload/Root";

const defaultConnectorData = {
  json_config: "",
  datasource_id: "",
  solution_name: "",
  kc_type: "",
  key_map: "",
  table_name: "",
  hours: 24,
  is_enabled: false,
  track_coverage: false,
};
const useStyles = makeStyles((theme) => ({
  root: theme.layout.root,
  content: theme.layout.content,
  contentWrapper: theme.layout.contentWrapper,
  loading: {
    height: "100vh",
    width: "100vw",
    position: "fixed",
    opacity: ".5",
    background: "white",
    zIndex: 10000,
    top: -50,
    left: 0,
  },
  secondaryNav: theme.layout.secondaryNav,
  tabPanel: {
    background: theme.palette.background,
  },
  companySection: {
    padding: theme.spacing(2),
    marginBottom: 16,
    background: "white",
    width: "100%",
  },
  wrapper: {
    position: "relative",
    margin: theme.spacing(1),
  },
  fabProgress: {
    color: green[500],
    position: "absolute",
    top: -6,
    left: -6,
    zIndex: 1,
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  grid: {
    display: "grid",
    gridGap: 5,
    gridAutoFlow: "column",
    width: "100%",
    gridTemplateColumns: "100 100",
    justifyContent: "end",
  },
}));

function ConnectorDetails() {
  const params = useParams();
  const { connectorId } = params;
  const refreshDropdowns = "";
  const [editMode, setEditMode] = useState(false);
  const [data, setData] = useState(null);
  const [datasources, setDatasources] = useState(null);
  const [hasError, setError] = useState(true);
  const api = useApi();
  const classes = useStyles();
  const [stats, setStats] = useState(null);
  const [kcTypes, setKcTypes] = useState([]);
  const [validSolutions, setValidSolutions] = useState([]);
  const toastContext = useContext(ToastContext);
  const [jsonError, setJsonError] = useState(false);
  const [connectorData, setConnectorData] = useState(defaultConnectorData);
  const [mappingData, setMappingData] = useState({});
  const [fieldSchema, setFieldSchema] = useState({});

  const checkError = (connectorData) => {
    if (hasError === true) {
      setError(false);
    }
    if (!connectorId) {
      if (
        !connectorData?.kc_type?.length ||
        !connectorData?.solution_name?.length ||
        !connectorData?.datasource_id ||
        !connectorData?.hours
      ) {
        setError(true);
      }
    }
  };

  const handleConnectorInputChange = (e) => {
    const { name, value, checked } = e.target;
    let newValue = value;
    if (name === "is_enabled" || name === "track_coverage") {
      newValue = checked;
    }
    if (name === "hours") {
      newValue = +value;
    }

    if (name === "json_config") {
      if (value) {
        try {
          JSON.parse(value);
          setJsonError(false);
          setError(false);
        } catch {
          setJsonError(true);
          setError(true);
        }
      } else {
        setJsonError(false);
        setError(false);
      }
    }

    const newData = {
      ...data,
      ...connectorData,
      [name]: newValue,
    };

    checkError(newData);
    setConnectorData(newData);
  };

  const handleMappingInputChange = (e) => {
    const { name, value } = e.target;
    const newValue = value;

    const newMapping = {
      ...mappingData,
      [name]: newValue,
    };

    if (newValue === "") {
      delete newMapping[name];
    }

    setMappingData(newMapping);
  };

  const handleSaveConnector = (type) => {
    const id = connectorData.id ? connectorData.id : "new";

    let requestData = {
      ...data,
    };

    if (type === "mappings") {
      if (!requestData.json_config) {
        requestData.json_config = {};
      }
      requestData.json_config.csv = {
        ...(requestData.json_config.csv || {}),
        mappings: Object.entries(mappingData).map(([key, value]) => {
          return `${key}=${value}`;
        }),
      };

      requestData.json_config = JSON.stringify(requestData.json_config);
    } else if (type === "advanced") {
      requestData.json_config = connectorData.json_config;
    } else {
      requestData = {
        ...connectorData,
        json_config: data.json_config,
      };
    }

    api
      .upsertConnector(id, requestData)
      .then((res) => {
        if (res.status === "OK") {
          toastContext.showToast(
            "open",
            "success",
            "Connector has been saved."
          );
          setConnectorData(requestData);
        } else {
          toastContext.showToast(
            "open",
            "error",
            "There was an error saving the configuration. Please check and try again."
          );
          setConnectorData(connectorData);
        }
      })
      .catch((err) => {
        console.log(err);
        toastContext.showToast(
          "open",
          "error",
          "There was an error saving the configuration. Please check and try again."
        );
        setConnectorData(connectorData);
      });
  };

  useEffect(() => {
    api
      .getDatasources()
      .then((response) => {
        setDatasources(response);
      })
      .catch((error) => console.log(error));
  }, [refreshDropdowns]);

  useEffect(() => {
    api
      .getConnectorOptions()
      .then((resp) => {
        setKcTypes(resp?.kc_types);
        setValidSolutions(resp?.solutions);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    if (!connectorId || connectorId < 1) {
      return;
    }
    api
      .getConnector(connectorId)
      .then((response) => {
        setData(response);
        if (response.json_config?.csv?.mappings) {
          const newMapping = {};
          response.json_config.csv.mappings.map((el) => {
            const split = el.split("=");
            const key = split[0];
            const value = split[1];
            newMapping[key] = value;
            return el;
          });

          setMappingData(newMapping);
        }
      })
      .catch((error) => console.log(error));
  }, [connectorId]);

  useEffect(() => {
    if (!data) {
      return;
    }

    setConnectorData({
      ...data,
      json_config: data.json_config ? JSON.stringify(data.json_config) : "",
    });
  }, [data]);

  useEffect(async () => {
    const res = await api.connectorStats();

    const connectorStats = res.stats?.find(
      (el) => el.connector === +connectorId
    );
    setStats(connectorStats);
  }, []);

  useEffect(async () => {
    const schema = await api.getConnectorFields();
    const mapping = {
      "str": "String",
      "int": "Integer",
      "bool": "Boolean",
      "date": "Data",
      "float": "Float",
      "long": "Long",
      "timestamp": "Timestamp",
      "arr_str": "String Array",
      "arr_int": "Integer Array"
    }

    for (const type in schema) {
      for (const properties of schema[type]) {
        properties['model_type'] = mapping[properties["model_type"]] ? mapping[properties["model_type"]] : properties['model_type'];
      }
    }
    setFieldSchema(schema);
  }, []);

  document.title = "KeyCaliber - Connector Details";

  return (
    <Box className={classes.root}>
      <div className={classes.contentWrapper}>
        <Box className={classes.content}>
          <Paper className={classes.companySection} variant="outlined">
            <Typography variant="h5" component="h1">
              {!editMode && (
                <div>
                  Details
                  <IconButton
                    aria-label="edit"
                    onClick={() => setEditMode(true)}
                    style={{ float: "right" }}
                    data-test="edit-company-profile-button"
                  >
                    <EditIcon />
                  </IconButton>
                </div>
              )}
              {editMode && <div style={{ marginBottom: 16 }}>Details</div>}
            </Typography>

            {!editMode && (
              <Grid container xs={12} spacing={2} style={{ padding: "1em" }}>
                <Grid item container alignItems="center">
                  <Grid item xs={2}>
                    <Typography variant="subtitle2">Solution</Typography>
                  </Grid>
                  <Grid item xs={10}>
                    {connectorData.solution ? (
                      connectorData.solution.logo_url &&
                        connectorData.solution.logo_url !== "" ? (
                        <img
                          src={connectorData.solution.logo_url}
                          width="80px"
                          // className={classes.logo}
                          alt={`${connectorData.solution.display_name} Logo`}
                        />
                      ) : (
                        <div>{connectorData.solution.display_name}</div>
                      )
                    ) : (
                      <div />
                    )}
                  </Grid>
                </Grid>
                <Grid item container alignItems="center">
                  <Grid item xs={2}>
                    <Typography variant="subtitle2">Data Source</Typography>
                  </Grid>
                  <Grid item xs={10}>
                    {connectorData.datasource ? (
                      connectorData.datasource.logo_url &&
                        connectorData.datasource.logo_url !== "" ? (
                        <img
                          src={connectorData.datasource.logo_url}
                          width="80px"
                          // className={classes.logo}
                          alt={`${connectorData.datasource.display_name} Logo`}
                        />
                      ) : (
                        <div>{connectorData.datasource.display_name}</div>
                      )
                    ) : (
                      <div />
                    )}
                  </Grid>
                </Grid>
                <Grid item container alignItems="center">
                  <Grid item xs={2}>
                    <Typography variant="subtitle2">Last Ran</Typography>
                  </Grid>
                  <Grid item xs={10}>
                    {stats?.date_last_run ? (
                      <>
                        <span>
                          {`${moment(stats?.date_last_run).local().fromNow()} `}
                        </span>

                        <Typography
                          style={{ color: "#797979" }}
                          display="block"
                          variant="caption"
                        >
                          {stats?.date_last_run}
                        </Typography>
                      </>
                    ) : (
                      "--"
                    )}
                  </Grid>
                </Grid>
                <Grid item container alignItems="center">
                  <Grid item xs={2}>
                    <Typography variant="subtitle2">Run Every</Typography>
                  </Grid>
                  <Grid item xs={10}>
                    {connectorData.hours} Hours
                  </Grid>
                </Grid>
                <Grid item container alignItems="center">
                  <Grid item xs={2}>
                    <Typography variant="subtitle2">Table</Typography>
                  </Grid>
                  <Grid item xs={10}>
                    {connectorData.table_name}
                  </Grid>
                </Grid>
                <Grid item container alignItems="center">
                  <Grid item xs={2}>
                    <Typography variant="subtitle2">Category</Typography>
                  </Grid>
                  <Grid item xs={10}>
                    {kcTypes?.find((el) => el.name === connectorData.kc_type)
                      ?.display_name
                      ? kcTypes?.find((el) => el.name === connectorData.kc_type)
                        ?.display_name
                      : "--"}
                  </Grid>
                </Grid>
              </Grid>
            )}
            {editMode && (
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  {validSolutions && (
                    <TextField
                      select={!connectorId}
                      disabled={!!connectorId}
                      label="Solution"
                      placeholder="Solution"
                      id="solution-select"
                      error={
                        !connectorId && !connectorData?.solution_name?.length
                      }
                      value={
                        data?.solution?.display_name ||
                        connectorData.solution_name
                      }
                      onChange={handleConnectorInputChange}
                      fullWidth
                      variant="outlined"
                      name="solution_name"
                      data-test="connector-form-solution"
                    >
                      {validSolutions.map((solution) => (
                        <MenuItem key={solution.name} value={solution.name}>
                          {solution.display_name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                </Grid>

                <Grid item>
                  {datasources && (
                    <TextField
                      select={!connectorId}
                      disabled={!!connectorId}
                      label="Data Source"
                      placeholder="Data Source"
                      id="datasource-select"
                      error={!connectorId && !connectorData?.datasource_id}
                      value={
                        data?.datasource?.display_name ||
                        connectorData.datasource_id
                      }
                      onChange={handleConnectorInputChange}
                      fullWidth
                      variant="outlined"
                      name="datasource_id"
                      data-test="connector-form-datasource"
                    >
                      {datasources?.map((datasource, idx) => (
                        <MenuItem key={idx} value={datasource.id}>
                          {datasource.display_name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                </Grid>
                <Grid item>
                  {kcTypes && (
                    <TextField
                      select
                      placeholder="Category"
                      label="Category"
                      id="type-select"
                      value={connectorData.kc_type || data?.kc_type}
                      onChange={handleConnectorInputChange}
                      fullWidth
                      variant="outlined"
                      error={!connectorData.kc_type?.length}
                      name="kc_type"
                      data-test="connector-form-type"
                      disabled
                    >
                      {kcTypes.map((dataType) => (
                        <MenuItem key={dataType.id} value={dataType.name}>
                          {dataType.display_name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                </Grid>
                <Grid item>
                  <TextField
                    name="table_name"
                    label="Bucket Prefix or SIEM Table Name"
                    onChange={handleConnectorInputChange}
                    variant="outlined"
                    fullWidth
                    value={connectorData.table_name || ""}
                    data-test="connector-form-tablename"
                    InputProps={{
                      classes: {
                        input: classes.smallerInput,
                      },
                    }}
                  />
                </Grid>
                <Grid container spacing={2} justifyContent="space-between" item>
                  <Grid item>
                    <TextField
                      name="hours"
                      onChange={handleConnectorInputChange}
                      variant="outlined"
                      type="number"
                      label="Run Every"
                      value={connectorData.hours || ""}
                      data-test="connector-form-hours"
                      helperText="In Hours"
                    />
                  </Grid>
                  <Grid item>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Switch
                            name="is_enabled"
                            checked={connectorData.is_enabled}
                            onChange={handleConnectorInputChange}
                            data-test="connector-form-enabled"
                          />
                        }
                        label="Enabled"
                      />
                      <FormControlLabel
                        control={
                          <Switch
                            name="track_coverage"
                            checked={connectorData.track_coverage}
                            onChange={handleConnectorInputChange}
                            data-test="connector-form-track-coverage"
                          />
                        }
                        label="Track Coverage"
                      />
                    </FormGroup>
                  </Grid>
                </Grid>
                <Grid className={classes.grid}>
                  <Button
                    style={{ margin: "1rem" }}
                    variant="contained"
                    color="secondary"
                    disableElevation
                    onClick={() => {
                      setEditMode(false);
                      handleSaveConnector("details");
                    }}
                    data-test="save-company-profile-button"
                  >
                    Save
                  </Button>
                  <Button
                    variant="outlined"
                    style={{ margin: "1rem 1rem 1rem 0" }}
                    onClick={() => {
                      setEditMode(false);
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            )}
          </Paper>
          <Grid container direction="column" spacing={2}>
            <Accordion elevation={0}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                style={{ backgroundColor: "#F5F7F9" }}
              >
                <Typography
                  className={classes.heading}
                  style={{ color: palette.purple1 }}
                >
                  Field Mapping Settings
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ backgroundColor: "#F5F7F9" }} >
                <Paper className={classes.companySection} variant="outlined" >
                  <Grid direction="column">
                    <Grid
                      container
                      direction="row"
                      className={classes.row}
                      style={{ marginBottom: 32 }}
                    >
                      If left blank, KeyCaliber will use defaults.
                    </Grid>
                    {(fieldSchema[connectorData.kc_type] || []).map(
                      (field) => {
                        return (
                          <Grid
                            container
                            direction="row"
                            alignItems="center"
                            fullWidth
                            style={{
                              marginBottom: 8,
                            }}
                            className={classes.row}
                          >
                            <TextField
                              disabled
                              placeholder={field.display_name}
                              id="datasource-select"
                              value={field.display_name}
                              variant="outlined"
                              data-test="connector-form-datasource"
                            />
                            <Icon
                              style={{ marginLeft: 32, marginRight: 32 }}
                              alignItems="center"
                              aria-label="East"
                            >
                              <ArrowForward />
                            </Icon>
                            <TextField
                              label={field.default}
                              placeholder="Field Value"
                              id="datasource-select"
                              name={field.default}
                              value={mappingData[field.default] || ""}
                              variant="outlined"
                              onChange={handleMappingInputChange}
                              data-test="connector-form-datasource"
                            />

                            <div style={{ marginLeft: 32 }}>
                              <strong>({field.model_type})</strong>
                            </div>
                          </Grid>
                        );
                      }
                    )}
                    <Grid className={classes.grid}>
                      <Button
                        style={{ margin: "1rem", marginRight: 0 }}
                        variant="contained"
                        color="secondary"
                        disableElevation
                        onClick={() => {
                          handleSaveConnector("mappings");
                        }}
                        data-test="save-company-profile-button"
                      >
                        Save Mapping
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </AccordionDetails>
            </Accordion>

            <Accordion elevation={0}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                style={{ backgroundColor: "#F5F7F9" }}
              >
                <Typography
                  className={classes.heading}
                  style={{ color: palette.purple1 }}
                >
                  Advanced Settings
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ backgroundColor: "#F5F7F9" }}>
                <Paper className={classes.companySection} variant="outlined">
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    className={classes.row}
                  >
                    <Box item component={Grid} xs={12}>
                      <Typography variant="overline" display="block">
                        JSON configuration for Data Ingestion
                      </Typography>
                    </Box>
                    <Box
                      item
                      component={Grid}
                      xs={12}
                      className={classes.inputItems}
                    >
                      <TextField
                        placeholder="Paste Valid JSON here"
                        error={jsonError}
                        helperText={jsonError && "Must be valid JSON"}
                        name="json_config"
                        onChange={handleConnectorInputChange}
                        fullWidth
                        size="small"
                        multiline
                        minRows={8}
                        value={connectorData.json_config || ""}
                        InputProps={{
                          spellCheck: "false",
                          classes: {
                            input: classes.jsonInput,
                          },
                        }}
                      />
                    </Box>
                    <Grid className={classes.grid}>
                      <Button
                        style={{ margin: "1rem", marginRight: 0 }}
                        variant="contained"
                        color="secondary"
                        disableElevation
                        onClick={() => {
                          handleSaveConnector("advanced");
                        }}
                        data-test="save-company-profile-button"
                      >
                        Save Mapping
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </AccordionDetails>
            </Accordion>

            <Accordion elevation={0}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                style={{ backgroundColor: "#F5F7F9" }}
              >
                <Typography
                  className={classes.heading}
                  style={{ color: palette.purple1 }}
                >
                  Upload File
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ backgroundColor: "#F5F7F9" }}>
                <Upload
                  connectorId={connectorId}
                  solutionName={data?.solution?.name}
                  type="details"
                />
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Box>
      </div>
    </Box>
  );
}

export default ConnectorDetails;
