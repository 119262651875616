import React, { useMemo, useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { withStyles } from "@material-ui/core/styles";
import { withOktaAuth } from "@okta/okta-react";
import Cookies from "universal-cookie";
import API, { APIContext } from "./API";
import SPA from "./SPA";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    marginTop: 30,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
});

const Home = (props) => {
  const { getAccessTokenSilently } = useAuth0();
  const [token, setToken] = useState();

  useEffect(() => {
    if (props.authType === "auth0") {

      let isMounted = true;

      (async () => {
        try {
          const authToken = await getAccessTokenSilently();

          if (isMounted) {
            return authToken;
          }
        } catch (e) {
          // Handle errors such as `login_required` and `consent_required` by re-prompting for a login
          console.error(e);
          window.location.href = "/login";
        }
      })().then(token => {
        if (isMounted) setToken(token);
      });

      return () => {
        isMounted = false;
      };

    }
  }, [getAccessTokenSilently]);

  const cookies = new Cookies();
  const api = useMemo(() => {
    if (props.authType === "okta") {
      return new API(props.authState.accessToken.accessToken);

    } else if (props.authType === "auth0") {
      const apiObj = new API(token);
      apiObj.init(() => {
        apiObj.accessToken = token;
      });
      return apiObj;
    }

    const authToken = cookies.get("jwt_auth_token");
    if (authToken === undefined) {
      window.location.href = "/login";
    }

    return new API(authToken);
  }, [token]);

  if (!api?.accessToken) {
    return null;
  }

  return (
    <APIContext.Provider value={api}>
      <div>
        <SPA />
      </div>
    </APIContext.Provider>
  );
};

export default withStyles(styles)(withOktaAuth(Home));
