import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Card, Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useApi } from "../../API";
import ChartWrapper from "../../components/ChartWrapper";
import ChartWrapperHeader from "../../components/ChartWrapperHeader";
import { numberWithCommas } from "../../utils";

function CyberAssetChanges() {
  const [assetCount, setAssetCount] = useState(undefined);
  const [highImpactAssetCount, setHighImpactAssetCount] = useState(undefined);

  const [shadowCount, setShadowCount] = useState(undefined);
  const [highImpactShadowCount, setHighImpactShadowCount] = useState(undefined);
  const [connectorsWithNetwork, setConnectorsWithNetwork] = useState([]);
  const [nonNetworkConnectors, setNonNetworkConnectors] = useState(undefined);


  const api = useApi();

  const history = useHistory();

  const reroute = (url) => {
    history.push(url);
  };

  const rerouteShadowIT = (criticalOnly) => {
    let url = '/app/inventory/assets?managed=false'

    if (connectorsWithNetwork.length > 0) {
      let i = 0
      url += "&coverage="
      for (const id of connectorsWithNetwork) {

        if (i > 0) {
          url += `%2C${id}`
        } else {
          url += id
        }
        i++
      }
    }

    if (nonNetworkConnectors.length > 0) {
      let i = 0
      url += "&missing_coverage="
      for (const id of nonNetworkConnectors) {
        if (i > 0) {
          url += `%2C${id}`
        } else {
          url += id
        }
        i++
      }
    }

    if (criticalOnly) {
      url += '&is_critical=true'
    }

    url += '&date_added=7'

    history.push(url);
  }


  useEffect(async () => {
    const nonNetworkConnectorsArr = [];
    const connectorsWithNetworkArr = []
    const connectors = await api.getConnectors()
    for (const connector of connectors) {
      if (connector.kc_type === 'network') {
        const checkDuplicate = connectors.filter((el) => {
          return connector.solution_id === el.solution_id && el.kc_type !== 'network'
        })
        if (checkDuplicate.length === 0) {
          connectorsWithNetworkArr.push(connector.solution_id)
        }
      } else {
        nonNetworkConnectorsArr.push(connector.solution_id)
      }
    }

    setConnectorsWithNetwork(connectorsWithNetworkArr)
    setNonNetworkConnectors(nonNetworkConnectorsArr)

    const assetsCount = await api.assetsOverTime(false);
    const shadowCount = await api.shadowITMetricsOverTime(false);
    const highImpactAssetsCount = await api.assetsOverTime(true);
    const highImpactShadowCount = await api.shadowITMetricsOverTime(true);
    setHighImpactAssetCount(highImpactAssetsCount.data)
    setHighImpactShadowCount(highImpactShadowCount.data)
    setAssetCount(assetsCount.data)
    setShadowCount(shadowCount.data)
  }, []);

  return (
    <ChartWrapper excludeHeader>
      <ChartWrapperHeader chartName="Cyber Asset Changes in the Past Week" />
      <Grid
        container
        direction="row"
        justifyContent="space-around"
        textAlign="center"
        style={{ marginTop: "24px", marginBottom: "16px" }}
      >
        <Grid container item xs={4} justifyContent="center">
          <strong>All Assets</strong>
        </Grid>
        <Grid container item xs={4} justifyContent="center">
          {" "}
        </Grid>
        <Grid container item xs={4} justifyContent="center">
          <strong>High Impact Assets</strong>
        </Grid>
      </Grid>
      <Card>
        <Grid
          container
          direction="row"
          justifyContent="space-around"
          textAlign="center"
          style={{ marginTop: "12px", marginBottom: "12px" }}
        >
          <Grid container item xs={4} justifyContent="center" onClick={() => reroute('/app/inventory/assets?date_added=7')} style={{ cursor: "pointer" }}>
            <Typography variant="h5">
              {numberWithCommas(assetCount)}{" "}
            </Typography>
          </Grid>
          <Grid container item xs={4} justifyContent="center">
            New Assets
          </Grid>
          <Grid container item xs={4} justifyContent="center" onClick={() => reroute('/app/inventory/assets?date_added=7&is_critical=true')} style={{ cursor: "pointer" }}>
            <Typography variant="h5">
              {numberWithCommas(highImpactAssetCount)}{" "}
            </Typography>
          </Grid>
        </Grid>
      </Card>
      <Card style={{ marginBottom: "12px" }}>
        <Grid
          container
          direction="row"
          justifyContent="space-around"
          textAlign="center"
          style={{ marginTop: "12px", marginBottom: "12px" }}
        >
          <Grid container item xs={4} justifyContent="center" onClick={() => rerouteShadowIT(false)} style={{ cursor: "pointer" }}>
            <Typography variant="h5">{numberWithCommas(shadowCount)} </Typography>
          </Grid>
          <Grid container item xs={4} justifyContent="center">
            New Unmanaged Assets (Shadow IT)
          </Grid>
          <Grid container item xs={4} justifyContent="center" onClick={() => rerouteShadowIT(true)} style={{ cursor: "pointer" }}>
            <Typography variant="h5" >
              {numberWithCommas(highImpactShadowCount)}{" "}
            </Typography>
          </Grid>
        </Grid>
      </Card>

    </ChartWrapper >
  );
}
export default CyberAssetChanges;
