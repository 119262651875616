import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import Toolbar from "@material-ui/core/Toolbar";
import { Button } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import logo from "../assets/logo.svg";
import { PageLoader } from "./PageLoader";

const CallbackPage = () => {
  const { isLoading, error } = useAuth0();

  const handleBackToLogin = () => {
    window.location.href = "/login";
  }

  return (
    <div>
      <AppBar>
        <Toolbar>
          <IconButton color="inherit" aria-label="open drawer" edge="start" />
          <a href="/login" style={{ height: "23px", marginLeft: ".3em" }}>
            <img height="23px" src={logo} alt="Logo" />
          </a>
        </Toolbar>
      </AppBar>
      <Grid
        container
        style={{ paddingTop: "120px" }}
        justifyContent="center"
        alignItems="center"
        direction="column"
        spacing={0}
      >
          {isLoading && (
            <PageLoader />
          )}
          {error && (
             <Grid
             container
             justifyContent="center"
             alignItems="center"
             direction="column"
             spacing={2}
           >
              Oops... This user is not recognized!
            <Grid item xs={12}>
            <Button
              style={{
                top: '30vh',
                bottom: '30vh',
                maxWidth: '150px',
                maxHeight: '50px',
                minWidth: '100px',
                minHeight: '50px',
              }}
              color="primary"
              data-test="login-submit-btn"
              type="submit"
              variant="contained"
              onClick={handleBackToLogin}
            >
              Back to Login
            </Button>
          </Grid>
          </Grid>
          )}
      </Grid>
    </div>
  );
};

export default CallbackPage;
